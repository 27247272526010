import {
  Address,
  Commitment,
  Contact,
  DateTimePeriod,
  Item,
  ItemCreationParams,
  QuoteCreationParams,
  Stop,
  StopDetails,
  TransportRequest,
} from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import { Config } from "../config";

const UUID_DETECTION_REGEX = /((\w{4,12}-?)){5}/gi;

export const isUUID = (val: string | null | undefined): boolean => {
  return !!val?.match(UUID_DETECTION_REGEX);
};

// NOTE: a few helper methods that take care of picking the right item and type-casting.
export const getPickupFromTr = (tr: TransportRequest): Stop => {
  return tr.pickups[0];
};

export const getDeliveryFromTr = (tr: TransportRequest): Stop => {
  return tr.deliveries[0];
};

// Question: Why re-create the TR stop interface?
// Answer: Creating a more well-defined interface at the context-level means there is less need for
// type-casting downstream. Moreover, we made the decision to pull the first pickup/delivery
// in a centralized function, which makes any future changes about multi-stop TRs easier to handle.
export interface TypedStop {
  id: string | undefined;
  address: Address;
  contact: Contact;
  available_datetime_periods: DateTimePeriod[];
  active_commitment: Commitment | null;
  details: StopDetails;
  service_duration: number | null;
}

export interface TransportRequestStops {
  pickup: TypedStop;
  delivery: TypedStop;
}

const getTypedStop = (stop: Stop): TypedStop => {
  if (Config.NODE_ENV === "development") {
    /**
     * We check for environment because sometimes core fixtures lead to undefined values
     * (tried to find out why, but couldn't find it).
     * So this is just a monkey patch to be more forgiven in development, without weakening production types
     */
    return {
      id: getIdFromIri(stop?.["@id"]),
      address: stop?.address as Address,
      contact: stop?.contact as Contact,
      available_datetime_periods: stop?.available_datetime_periods || [],
      active_commitment: stop?.active_commitment,
      details: stop?.details,
      service_duration: stop?.service_duration || null,
    };
  }
  return {
    id: getIdFromIri(stop["@id"]),
    address: stop.address as Address,
    contact: stop.contact as Contact,
    available_datetime_periods: stop.available_datetime_periods,
    active_commitment: stop.active_commitment,
    details: stop.details,
    service_duration: stop.service_duration,
  };
};

export const getTypedStopsFromTr = (tr: TransportRequest): TransportRequestStops => {
  const pickup = getPickupFromTr(tr);
  const delivery = getDeliveryFromTr(tr);

  return {
    pickup: getTypedStop(pickup),
    delivery: getTypedStop(delivery),
  };
};

export const getQuoteParamsForTr = (tr: TransportRequest, account_id?: string): QuoteCreationParams => {
  const { pickup, delivery } = getTypedStopsFromTr(tr);
  return {
    account_id,
    pickup: {
      address: {
        lat: pickup.address.lat,
        lng: pickup.address.lng,
        country_code: pickup.address.country_code,
        administrative_area: pickup.address.administrative_area as string,
        locality: pickup.address.locality,
      },
      details: {
        situation: pickup.details.situation,
        carrying_help: pickup.details.carrying_help,
        floor_level: pickup.details.floor_level,
        elevator: pickup.details.elevator,
        extras: pickup.details.extras,
      },
      available_datetime_period: pickup.available_datetime_periods[0],
    },
    delivery: {
      address: {
        lat: delivery.address.lat,
        lng: delivery.address.lng,
        country_code: delivery.address.country_code,
        administrative_area: delivery.address.administrative_area as string,
        locality: delivery.address.locality,
      },
      details: {
        situation: delivery.details.situation,
        carrying_help: delivery.details.carrying_help,
        floor_level: delivery.details.floor_level,
        elevator: delivery.details.elevator,
        extras: delivery.details.extras,
      },
      available_datetime_period: delivery.available_datetime_periods[0],
    },
    time_travel: tr.created_at,
    utm: tr.utm || null,
    dolphins: tr.pricing_attributes.dolphins,
    item_sets: tr.item_sets.map((itemSet) => {
      return {
        items: (itemSet.items as Item[]).map((item): ItemCreationParams => {
          return {
            title: item.title,
            length: item.length,
            height: item.height,
            width: item.width,
            count: item.count,
            weight: item.weight || 0,
          };
        }),
      };
    }),
  };
};
