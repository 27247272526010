import { Locale, LocaleNormalizer } from "@brenger/api-client";
import { Config } from "../config";

// NOTE: use es6 MAP here so that we can perserve type information when accessing keys.
export const SUPPORTED_LOCALE_MAP = new Map<Locale, string>([
  ["en-NL", "🇪🇺 EN"],
  ["de-DE", "🇩🇪 DE"],
  ["nl-BE", "🇧🇪 NL"],
  ["nl-NL", "🇳🇱 NL"],
]);

export type SupportedLocales = "en-NL" | "nl-NL" | "de-DE" | "nl-BE";

export const SUPPORTED_LOCALE_CUSTOMER_MAP = new Map<Locale, "en-nl" | "de-de" | "nl-be" | "nl">([
  ["en-NL", "en-nl"],
  ["de-DE", "de-de"],
  ["nl-BE", "nl-be"],
  ["nl-NL", "nl"],
]);

export const localeNormalizer = new LocaleNormalizer({
  defaultLocale: "en-NL",
  validLocales: [...SUPPORTED_LOCALE_MAP.keys()],
  defaultTimeZone: "Europe/Amsterdam",
});

/**
 * @param locale auth locale
 * @param type For BE customer, we host the react app at the dutch domain (downside of having WP multisite with language directories, we could not have another dutch directory)
 * @returns domain + optional /customer
 */
export const getCustomerAppUrl = (locale: Locale, customerApp: boolean): string => {
  const customerAppLocale = SUPPORTED_LOCALE_CUSTOMER_MAP.get(locale) || "en-nl";
  // If you have something configured, then use that.
  if (Config.CUSTOMER_URL) return `${Config.CUSTOMER_URL}/${customerAppLocale}`;
  // When not configured we consider it to be prod and apply this domain mapping
  const domain = {
    nl: "https://www.brenger.nl/nl",
    "en-nl": "https://www.brenger.nl/en-nl",
    "nl-be": customerApp ? "https://www.brenger.nl/nl" : "https://www.brenger.be/nl-be",
    "de-de": "https://www.brengertransport.de/de-de",
  }[customerAppLocale];

  if (customerApp) return domain + "/customer";
  return domain;
};
