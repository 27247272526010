import { FoBaseParams, FoProcessingState } from "@brenger/api-client";
import { Button } from "@brenger/react";
import React from "react";
import { useQueryClient } from "react-query";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { Config, LOCALE_TEMPLATE } from "../../config";
import { useTranslationContext } from "../../hooks";
import { getCustomerAppUrl } from "../../utils";
import Waiting from "../assets/mp_waiting.gif";
import { MpContainer, MpFooterThankYou, ThankYouLayout } from "../components/Layout";
import { GenerateMpRoute, MpRoutes, MpRoutesSimpleType } from "../routes";

export const ProcessingPage: React.FC<{ state: FoProcessingState } & FoBaseParams> = (props) => {
  const history = useHistory();

  // We need a useEffect instead of redirect else the url stays the same
  React.useEffect(() => {
    history.push(
      GenerateMpRoute({ id: props.partyId, page: { type: props.state.toLowerCase() as MpRoutesSimpleType } })
    );
  }, [props.state]);

  return (
    <Switch>
      <Route path={MpRoutes.PROCESSING} component={Page} />
    </Switch>
  );
};

const Page: React.FC<FoBaseParams> = () => {
  const { t, i18n } = useTranslationContext();
  const params = useParams<{ id: string; processing_state: string }>();
  const state = params.processing_state as "processing" | "processing_error";
  const queryClient = useQueryClient();
  const history = useHistory();
  const continueAction = (): void => {
    if (state === "processing") {
      queryClient.clear();
      history.push(GenerateMpRoute({ id: params.id }));
      return;
    }
    window.open(getCustomerAppUrl(i18n.locale, false) + "/contact", "_blank");
  };
  return (
    <ThankYouLayout title={t((d) => d.fo.page[state].title)}>
      <MpContainer>{t((d) => d.fo.page[state].text)}</MpContainer>
      <MpContainer>
        <Button buttonType="secondary" onClick={continueAction}>
          {t((d) => d.fo.page[state].button)}
        </Button>
      </MpContainer>
      <div className="relative my-4">
        <div className="absolute right-0 left-1/2 border-t border-color-white mt-5" />
        <MpContainer className="relative">
          <div className="absolute right-4 left-8 border-t border-color-white mt-1" />
          <div className={"rounded-full w-3 h-3 bg-green-400 animate-pulse"} style={{ marginTop: "-.1rem" }} />
        </MpContainer>
      </div>
      <MpContainer fullWithBelowBreak="md">
        <img
          width={1000}
          height={1000}
          className={"max-w-full md:max-w-lg md:border md:border-white-1/2"}
          src={Waiting}
        />
      </MpContainer>
      <MpFooterThankYou>
        <MpContainer>
          <a
            target="_blank"
            rel="noreferrer"
            href={Config.CUSTOMER_FAQ_URL.replace(LOCALE_TEMPLATE, i18n.locale)}
            dangerouslySetInnerHTML={{ __html: t((d) => d.fo.waiting.faq_text) }}
          />
        </MpContainer>
      </MpFooterThankYou>
    </ThankYouLayout>
  );
};
