import { Button, IconEye, InputText, Label, Message, Spacer, useForm, useKeyPress } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { Page } from "../../../components";
import { useTranslationContext } from "../../../hooks";
import { Routes, coreClient, getCustomerAppUrl } from "../../../utils";

export const Login: React.FC = () => {
  const { t, i18n } = useTranslationContext();
  // NOTE: the referrer key is set in App.tsx - look there for more context.
  const enter = useKeyPress("Enter");
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  // hook to interact with cache / settings etc
  const queryClient = useQueryClient();
  const history = useHistory();

  const onSuccess = async (): Promise<void> => {
    queryClient.clear();
    history.push(Routes.TRANSPORT_LIST);
  };

  const login = useMutation(coreClient.users.login, { onSuccess });

  const form = useForm({
    initialState: { email: "", password: "" },
    validators: { email: (val) => !val, password: (val) => !val },
  });

  const submitForm = (): void => {
    login.mutate({
      email: form.data.email.value,
      password: form.data.password.value,
    });
  };

  /**
   * Handle "Enter" key press.
   * This allows users to login by hitting the enter button at any point on this page.
   */
  React.useEffect(() => {
    if (enter && !form.hasErrors) submitForm();
  }, [enter]);

  return (
    <Page>
      <div className="w-80 mx-auto">
        <Spacer h={4} />
        <Label text={t((d) => d.login_form.labels.email)}>
          <InputText
            autoComplete="email"
            className={cn("w-full")}
            value={form.data.email.value}
            onChange={(email) => void form.set({ email: email.trim() })}
          />
        </Label>
        <Spacer h={4} />
        <Label text={t((d) => d.login_form.labels.password)}>
          <div className={cn("relative")}>
            <InputText
              autoComplete="password"
              style={{ paddingRight: "3.4rem" }}
              className={cn("w-full")}
              value={form.data.password.value}
              type={isPasswordVisible ? "text" : "password"}
              onChange={(password) => form.set({ password: password.trim() })}
            />
            <button
              style={{ height: 42 }}
              className={cn("absolute", "right-0", "top-0", "px-3", {
                "opacity-100": isPasswordVisible,
                "opacity-50": !isPasswordVisible,
              })}
            >
              <IconEye className={cn("w-8", "h-8")} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            </button>
          </div>
        </Label>
        <Button
          buttonType="secondary"
          disabled={form.hasErrors}
          loading={login.isLoading}
          className={cn("mt-6", "w-full")}
          onClick={submitForm}
        >
          {t((d) => d.login_form.labels.submit)}
        </Button>
        {login.isError && (
          <Message className={cn("mt-4")} type="error">
            {/* For security reasons, always show the same error message when login fails. */}
            {t((d) => d.login_form.labels.error_message)}
          </Message>
        )}
        <div className="text-center underline mt-4">
          <a target="_blank" rel="noreferrer" href={`${getCustomerAppUrl(i18n.locale, true)}/password_reset`}>
            {t((d) => d.login_form.labels.reset_password)}
          </a>
        </div>
      </div>
    </Page>
  );
};
