import { Button, Spacer, useModalState, Modal } from "@brenger/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslationContext } from "../../hooks";
import { getCustomerAppUrl } from "../../utils";

export const PAYMENT_STATUS_PARAM = "payment_status";
// NOTE: it is a mollie status, they spell canceled American style, so single l
export const PAYMENT_STATUS_CANCELED = "canceled";

export const PaymentCancelledModal: React.FC = () => {
  const { t, i18n } = useTranslationContext();
  const modal = useModalState();
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get(PAYMENT_STATUS_PARAM) === PAYMENT_STATUS_CANCELED) {
      modal.open();
      history.replace(location.pathname);
    }
  }, []);

  return (
    <Modal {...modal}>
      <h3>{t((d) => d.payment_canceled.title)}</h3>
      <Spacer h={2} />
      <div
        className="text-with-links"
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __html: t((d, template) =>
            template(d.payment_canceled.text, { link: `${getCustomerAppUrl(i18n.locale, false)}/contact` })
          ),
        }}
      />
      <Spacer h={8} />
      <div>
        <Button buttonType="primary" onClick={modal.close}>
          {t((d) => d.payment_canceled.ok)}
        </Button>
      </div>
    </Modal>
  );
};
