import {
  CoreClient,
  FlowOrchestratorClient,
  PriceClient,
  RoutePlannerClient,
  UserDataClient,
  parseErrorMessageFromCore,
} from "@brenger/api-client";
import axios from "axios";

import { uuid } from "@brenger/utils";
import { Config } from "../config";
import { logger } from "../utils";

// Use these cache keys to set network responses in an in-memory cache
// See useQuery for implementation details.
export enum CacheKey {
  TRANSLATIONS,
  RETRIEVE_USER,
  RETRIEVE_BUSINESS_DATES_LIST,
  RETRIEVE_PRODUCT_PAYMENT,
  RETRIEVE_HAS_REVIEWS,
  RETRIEVE_HAS_DELIVERY_REVIEWS,
  RETRIEVE_MESSAGES,
  RETRIEVE_DRIVER_LOCATION,
  RETRIEVE_TR,
  RETRIEVE_TR_LIST,
  RETRIEVE_LIST_DETAILS,
  RETRIEVE_ITEM_SETS,
  RETRIEVE_ETA_PERIOD,
  RETRIEVE_OPEN_FOR_UPSELLS,
  RETRIEVE_TJAL_LIST,
  RETRIEVE_LIVE_TRACKING_STATUS,
  RETRIEVE_ADDRESS,
  RETRIEVE_CANCELATION_COSTS,
  RETRIEVE_STOP,
  RETRIEVE_CUSTOMER,
  FO_RETRIEVE_DRAFT_TR = "FO_RETRIEVE_DRAFT_TR",
  FO_RETRIEVE_SELECTED_DATES = "FO_RETRIEVE_SELECTED_DATES",
  FO_RETRIEVE_CONTACT = "FO_RETRIEVE_MP_CONTACT",
  FO_UPDATE_CONTACT = "FO_UPDATE_MP_CONTACT",
  FO_SELECT_SERVICE = "FO_SELECT_SERVICE",
  FO_RETRIEVE_AVAILABLE_DATES = "FO_RETRIEVE_AVAILABLE_DATES",
  FO_UPDATE_SELECTED_DATES = "FO_UPDATE_SELECTED_DATES",
  FO_RETRIEVE_CONFIRMED_DATE = "FO_RETRIEVE_CONFIRMED_DATE",
  FO_CONFIRM_DATE = "FO_CONFIRM_DATE",
  FO_REQUEST_MORE_DATES = "FO_REQUEST_MORE_DATES",
  FO_RETRIEVE_AVAILABLE_SERVICES = "FO_RETRIEVE_AVAILABLE_SERVICES",
  FO_RETRIEVE_SELECTED_SERVICE = "FO_RETRIEVE_SELECTED_SERVICE",
  FO_TRIGGER_COMPLETE = "FO_TRIGGER_COMPLETE",
  FO_TRIGGER_CANCEL = "FO_TRIGGER_CANCEL",
  FORM_FEASIBLE = "FORM_FEASIBLE",
}

// Some standard TTL to use throughout app.s
export enum CacheTTL {
  /**
   * 1 minute.
   */
  XS = 60,
  /**
   * 2 minutes.
   */
  SM = 120,
  /**
   * 5 minutes.
   */
  MD = 300,
  /**
   * 10 minutes.
   */
  LG = 600,
  /**
   * 60 minutes.
   */
  XL = 3600,
  /**
   * A full day.
   */
  XXL = 3600 * 24,
}

export const SANDBOX_BASENAME = "/sandbox";

export const isSandboxMode = (): boolean => {
  return window.location.pathname.startsWith(SANDBOX_BASENAME);
};

/**
 * SET UP CORE CLIENT
 */
const coreBaseURL = isSandboxMode() ? Config.SANDBOX_API_URL : Config.API_URL;
export const coreAxiosInstance = axios.create({
  baseURL: coreBaseURL,
  withCredentials: true,
  // A 30s global timeout for network requests to core.
  timeout: 30000,
  headers: {
    Accept: "application/ld+json",
    "Content-Type": "application/ld+json",
  },
});

// 403 and 404 are uninteresting and will create too much noise.
const blacklistStatusCodesFromCore = [403, 404];

interface InRangeParams {
  val?: number;
  min: number;
  max: number;
  ignore?: number[];
}

export const isValueInRange = ({ val, min, max, ignore }: InRangeParams): boolean => {
  return typeof val === "number" && val >= min && val <= max && !ignore?.includes(val);
};

coreAxiosInstance.interceptors.request.use((config) => {
  config.headers["X-Brenger-Trace-Id"] = config.headers["X-Brenger-Trace-Id"] || uuid();
  return config;
});

coreAxiosInstance.interceptors.response.use(undefined, (responseError) => {
  const errorMessage = parseErrorMessageFromCore(responseError);
  const extra = { ...responseError?.response, ...responseError?.request };
  const status: number = responseError?.response?.status || -1;

  const isNoteworthyError = isValueInRange({
    val: status,
    min: 400,
    max: 499,
    ignore: blacklistStatusCodesFromCore,
  });

  // Both errors happen when a stop contact page is querying for live status
  // This is only works for customer tr pages
  const errorMessagesToBeIgnored = ["active_tjal_not_found", "tj_not_found", "tl_not_found"];

  if (isNoteworthyError && !errorMessagesToBeIgnored.includes(errorMessage)) {
    logger.message(`[CORE] ${status} - ${errorMessage}`, { extra });
  }

  const error = new Error(errorMessage);

  return Promise.reject(error);
});

export const coreClient = new CoreClient(coreAxiosInstance);

export const userDataClient = new UserDataClient(Config.USER_DATA_URL || "");

/**
 * SET UP PRICING CLIENT
 */
const priceAxiosInstance = axios.create({
  baseURL: Config.PRICE_API_URL,
  timeout: 30000,
});

priceAxiosInstance.interceptors.request.use((config) => {
  config.headers["X-Brenger-Trace-Id"] = config.headers["X-Brenger-Trace-Id"] || uuid();
  return config;
});

priceAxiosInstance.interceptors.response.use(undefined, (error) => {
  const status = error?.response?.status || "unknown";
  logger.message(`[PRICING] ${status}`, { extra: { ...error?.response, ...error?.request } });
  return Promise.reject(error);
});

export const priceClient = new PriceClient(priceAxiosInstance);

/**
 * SET UP GEO CLIENT
 */
const routePlannerAxiosInstance = axios.create({
  baseURL: Config.ROUTE_PLANNER_URL,
});

routePlannerAxiosInstance.interceptors.request.use((config) => {
  config.headers["X-Brenger-Trace-Id"] = config.headers["X-Brenger-Trace-Id"] || uuid();
  return config;
});

routePlannerAxiosInstance.interceptors.response.use(undefined, (error) => {
  const status = error?.response?.status || "unknown";
  const isNoteworthyError = isValueInRange({
    val: status,
    min: 400,
    max: 499,
    ignore: blacklistStatusCodesFromCore,
  });

  if (isNoteworthyError) {
    logger.message(`[GEO] ${status}`, { extra: { ...error?.response, ...error?.request } });
  }

  return Promise.reject(error);
});

export const routePlannerClient = new RoutePlannerClient(routePlannerAxiosInstance);

/**
 * SET UP FLOW ORCHESCTRATOR CLIENT
 */
const foBaseURL = isSandboxMode() ? Config.SANDBOX_EXTERNAL_API_URL : Config.EXTERNAL_API_URL;
const flowOrchestratorAxiosInstance = axios.create({
  baseURL: foBaseURL,
  timeout: 30000,
});

flowOrchestratorAxiosInstance.interceptors.request.use((config) => {
  config.headers["X-Brenger-Trace-Id"] = config.headers["X-Brenger-Trace-Id"] || uuid();
  return config;
});

flowOrchestratorAxiosInstance.interceptors.response.use(undefined, (error) => {
  const status = error?.response?.status || "unknown";
  const isNoteworthyError = isValueInRange({
    val: status,
    min: 400,
    max: 499,
    ignore: blacklistStatusCodesFromCore,
  });

  if (isNoteworthyError) {
    logger.message(`[FO] ${status}`, { extra: { ...error?.response, ...error?.request } });
  }

  return Promise.reject(error);
});

export const foClient = new FlowOrchestratorClient(flowOrchestratorAxiosInstance);
